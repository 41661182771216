import { ConfigProvider } from "antd";
import React, { useState } from "react";
import Header from "./Layouts/Header";
import SignUpLayout from "./Layouts/SignUp";
import FooterLayout from "./Layouts/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import Main from "./Routes";
import ReactGA from "react-ga";
import { AuthProvider } from "./Contexts/AuthContext";
import { LoginModalContext } from "./Contexts/LoginModalContext";
import LoginModal from "./Layouts/Header/Components/LoginModal";

ReactGA.initialize("UA-257348869-1");
ReactGA.pageview(window.location.pathname);

const customTheme = {
	token: {
		colorPrimary: "#cc78d4",
		colorLink: "red",
	},
	components: {
		Button: {
			borderRadius: "23.36px",
			backgroundImage: "linear-gradient(to right, #cc78d4, #5f5f5f)",
		},
	},
};

const App = () => {
	const [loginVisible, setLoginVisible] = useState(false);
	return (
		<ConfigProvider theme={customTheme}>
			<AuthProvider>
				<LoginModalContext.Provider value={{ loginVisible, setLoginVisible }}>
					<div className="main-app">
						<Header />
						<Main />
						<SignUpLayout />
						<FooterLayout />
						{/* <LoginModal /> */}
					</div>
				</LoginModalContext.Provider>
			</AuthProvider>
		</ConfigProvider>
	);
};

export default App;
