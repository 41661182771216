import { useState } from "react";
import { Modal, Form, Input, Button } from "antd";
import axios from "axios";
import { notification } from "antd";

export default function PasswordResetModal() {
	const [loading, setLoading] = useState(false);

	const [visible, setVisible] = useState(false);

	const handleCancel = () => {
		setVisible(false);
	};

	const onFinish = async (values) => {
		console.log("Received values of form: ", values);
		setLoading(true);
		await axios
			.post(
				process.env.REACT_APP_BACKEND_BASE_URL +
					"/api/user/send-reset-password-email/",
				values
			)
			.then((response) => {
				if (response.status === 200) {
					console.log(response);
					notification.success({
						message: "Email sent",
						description:
							"Please check your inbox, we have sent you an email to reset your password",
						placement: "top",
						duration: 0,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				if (
					error.response &&
					error.response.data &&
					error.response.data.message
				) {
					notification.error({
						message: "Password reset failed",
						description: error.response.data.message,
						placement: "top",
						duration: 0,
					});
				} else {
					notification.error({
						message: "Password reset failed",
						description: error.message,
						placement: "top",
						duration: 0,
					});
				}
			});

		setLoading(false);
	};

	return (
		<div>
			<span
				onClick={() => setVisible(true)}
				style={{
					color: "purple",
					textDecoration: "underline",
					cursor: "pointer",
				}}
			>
				Reset your password
			</span>
			<Modal
				title=<p style={{ textAlign: "center" }}>Reset your password</p>
				open={visible}
				onCancel={handleCancel}
				footer={[
					<Button
						key="submit"
						type="primary"
						form="password-reset-form"
						htmlType="submit"
						loading={loading}
					>
						Send reset link
					</Button>,
				]}
			>
				<Form name="password-reset-form" onFinish={onFinish}>
					<Form.Item
						name="email"
						rules={[
							{
								type: "email",
								message: "Please enter a valid email address",
							},
							{
								required: true,
								message: "Email address is required",
							},
						]}
					>
						<Input placeholder="Enter your email address" />
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
}
