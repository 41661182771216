import { useState } from "react";
import { Form, Input, Button } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { notification } from "antd";
import { useParams } from "react-router-dom";

export default function PasswordReset() {
	const navigate = useNavigate();
	const { token } = useParams();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const onFinish = async (values) => {
		setLoading(true);
		const payload = { token: token, new_password: values.new_password };
		await axios
			.post(
				process.env.REACT_APP_BACKEND_BASE_URL + "/api/user/reset-password/",
				payload
			)
			.then((response) => {
				if (response.status === 200) {
					notification.success({
						message: "Password reset successful",
						description: "You can now login with your new password.",
						placement: "top",
					});
					navigate("/");
				} else {
					notification.error({
						message: "Password reset failed",
						description: response.data,
						placement: "top",
					});
				}
			})
			.catch((error) => {
				console.log(error);
				if (
					error.response &&
					error.response.data &&
					error.response.data.message
				) {
					notification.error({
						message: "Password reset failed",
						description: error.response.data.message,
						placement: "top",
						duration: 0,
					});
				} else {
					notification.error({
						message: "Password reset failed",
						description: error.message,
						placement: "top",
						duration: 0,
					});
				}
			});
		setLoading(false);
	};

	const handleCancel = () => {
		navigate("/");
	};

	const validatePassword = (_, value) => {
		if (value && value.length < 8) {
			return Promise.reject(
				new Error("Password must be at least 8 characters")
			);
		}
		return Promise.resolve();
	};

	return (
		<div className="mx-auto px-3 my-5" style={{ maxWidth: "800px" }}>
			<Form
				form={form}
				onFinish={onFinish}
				layout="vertical"
				requiredMark={true}
			>
				<Form.Item
					label="New Password"
					name="new_password"
					rules={[
						{ required: true, message: "Please enter your new password" },
						{ validator: validatePassword },
					]}
				>
					<Input.Password placeholder="Enter your new password" />
				</Form.Item>

				<Form.Item
					label="Confirm New Password"
					name="confirmNewPassword"
					dependencies={["new_password"]}
					rules={[
						{ required: true, message: "Please confirm your new password" },
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue("new_password") === value) {
									return Promise.resolve();
								}
								return Promise.reject(new Error("Passwords do not match"));
							},
						}),
					]}
				>
					<Input.Password placeholder="Confirm your new password" />
				</Form.Item>

				<Form.Item>
					<Button type="primary" htmlType="submit" loading={loading}>
						Reset Password
					</Button>
					<Button onClick={handleCancel} style={{ marginLeft: 8 }}>
						Cancel
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
}
