import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Disable console.log() in production
if (process.env.NODE_ENV === "production") {
	console.log = function () {};
}

root.render(
	// <React.StrictMode>
	<Router>
		<App />
	</Router>
	// </React.StrictMode>
);
