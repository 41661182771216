import axios from "axios";
import "./index.css";
import { useEffect, useState, useContext } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router";
import Card from "./Components/Card";
import Description from "./Components/Description";
import HrContainer from "../../Components/GreyHrContainer";
import { AuthContext } from "../../Contexts/AuthContext";
import {
	AuthAxiosRequest,
	setupAuthInterceptor,
} from "../../Services/Utils/AuthAxiosRequest";
import { Spin } from "antd";
import { LoginModalContext } from "../../Contexts/LoginModalContext";

export default function Art() {
	const { artName } = useParams();
	const [artData, setArtData] = useState(null);
	const [bookmarked, setBookmarked] = useState(false);
	const [loading, setLoading] = useState(true);
	const { isAuthenticated } = useContext(AuthContext);
	const { setLoginVisible } = useContext(LoginModalContext);
	useEffect(() => {
		setupAuthInterceptor(setLoginVisible);
		axios
			.get(process.env.REACT_APP_BACKEND_BASE_URL + `/api/get_art/${artName}/`)
			.then((response) => {
				const data = response.data;
				setArtData(data);
				if (isAuthenticated) {
					AuthAxiosRequest.get("user/bookmarks/")
						.then((response) => {
							const bookmarksData = response.data.filter(
								(obj) => obj.art !== 5
							);
							if (bookmarksData[0].arts.includes(data.id)) {
								setBookmarked(true);
							}
							setLoading(false);
						})
						.catch(() => {
							setLoading(false);
						});
				} else {
					setLoading(false);
				}
			});
	}, []);
	return (
		<div className="auction-page">
			{loading ? (
				<div style={{ height: "100vh", width: "100vw" }} className="center-div">
					<Spin spinning={loading}></Spin>
				</div>
			) : (
				<>
					<Card
						artData={artData}
						artName={artName}
						setArtData={(data) => setArtData(data)}
						bookmarked={bookmarked}
						setBookmarked={(bookmarked) => setBookmarked(bookmarked)}
					/>
					<Container>
						<h4 className="m-0" style={{ color: "purple" }}>
							Description
						</h4>
					</Container>
					<HrContainer style={{ margin: "5px 0 5px 0" }} />
					<Container>
						<h4 className="m-0" style={{ color: "purple" }}>
							{artName}
						</h4>
					</Container>
					<br />
					<Description artData={artData} />
				</>
			)}
		</div>
	);
}
