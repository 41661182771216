import axios from "axios";
import jwt_decode from "jwt-decode";

const AuthAxiosRequest = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_BASE_URL + "/api/",
	timeout: 10000,
	headers: {
		"Content-Type": "application/json",
		accept: "application/json",
	},
});

let refreshing = false;

const createInterceptor = (setLoginVisible) => async (config) => {
	const token = localStorage.getItem("access_token");
	const refreshToken = localStorage.getItem("refresh_token");

	if (token) {
		// Check if the access token has expired
		const decodedToken = jwt_decode(token);
		const currentTime = Date.now() / 1000;
		if (decodedToken.exp < currentTime + 5) {
			// Access token has expired, try to get a new one with the refresh token
			if (refreshToken && !refreshing) {
				// check if refreshing flag is false
				try {
					refreshing = true; // set refreshing flag to true
					console.log("---------------- get_access_token 1", {
						refresh: refreshToken,
					});
					const response = await axios.post(
						process.env.REACT_APP_BACKEND_BASE_URL + "/api/token/refresh/",
						{
							refresh: refreshToken,
						}
					);
					console.log("---------------- get_access_token 2", {
						response,
					});
					localStorage.setItem("access_token", response.data.access);
					console.log("---------------- get_access_token 3", {
						access_token: response.data.access,
					});
					config.headers["Authorization"] = `Bearer ${response.data.access}`;
				} catch (error) {
					// Refresh token has expired or is invalid, redirect to login page
					console.log(
						"---------------- get_access_token 8 Refresh token has expired or is invalid, redirect to login page"
					);
					// window.location.href = "/loginnnnnn";
					setLoginVisible(true);
				} finally {
					refreshing = false; // set refreshing flag back to false
				}
			} else {
				config.headers["Authorization"] = `Bearer ${token}`; // use existing access token
			}
		} else {
			config.headers["Authorization"] = `Bearer ${token}`;
		}
	} else {
		// No access token available, redirect to login page
		console.log(
			"---------------- get_access_token 9 No access token available, redirect to login page"
		);
		setLoginVisible(true);
		// window.location.href = "/logiiiiiin";
	}
	console.log("---------------- get_access_token 4", {
		config: config,
	});
	return config;
};

const setupAuthInterceptor = (setLoginVisible) => {
	AuthAxiosRequest.interceptors.request.use(
		createInterceptor(setLoginVisible),
		(error) => {
			return Promise.reject(error);
		}
	);
};

export { AuthAxiosRequest, setupAuthInterceptor };
