import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { notification } from "antd";
import { AuthContext } from "../../../../Contexts/AuthContext";
import { CgProfile } from "react-icons/cg";
import { Dropdown } from "antd";

export default function LogOutButton() {
	const navigate = useNavigate();
	const { setIsAuthenticated } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const userMenu = [
		{
			key: "1",
			label: (
				<a
					target="_blank"
					rel="noopener noreferrer"
					href="https://www.antgroup.com"
				>
					My account
				</a>
			),
		},
		{
			key: "2",
			label: (
				<a
					target="_blank"
					rel="noopener noreferrer"
					href="https://www.aliyun.com"
				>
					Log out
				</a>
			),
		},
	];

	function handleLogout() {
		setLoading(true);
		setIsAuthenticated(false);
		localStorage.setItem("access_token", "");
		localStorage.setItem("refresh_token", "");
		setLoading(false);
		navigate("/");
		notification.success({
			message: "Logged out",
			description: "Logged out successfully",
			placement: "top",
		});
	}

	const items = [
		{
			key: "1",
			label: <Link to="/account">My account</Link>,
		},
		{
			key: "2",
			label: (
				<Link to="/" onClick={handleLogout}>
					Log out
				</Link>
			),
		},
	];

	return (
		<div>
			<Dropdown
				menu={{
					items,
				}}
				placement="top"
				arrow
			>
				<Link to="/account">
					<CgProfile
						style={{ color: "#D29DC3", fontSize: "2rem", cursor: "pointer" }}
					/>
				</Link>
			</Dropdown>
		</div>
	);
}
