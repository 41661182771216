import { notification, Image } from "antd";
import { Col, Row } from "react-bootstrap";
import "./index.css";
import { TbLivePhoto } from "react-icons/tb";
import { AiOutlineFieldTime } from "react-icons/ai";
import CountDown from "../CountDown";
import { Link } from "react-router-dom";
import { IoMdHeart } from "react-icons/io";
import {
	AuthAxiosRequest,
	setupAuthInterceptor,
} from "../../Services/Utils/AuthAxiosRequest";
import { useEffect, useState, useContext } from "react";
import { LoginModalContext } from "../../Contexts/LoginModalContext";

export default function DashboardAuctionCard(props) {
	const artData = props.artData;
	const [heartLoading, setHeartLoading] = useState(false);
	const { setLoginVisible } = useContext(LoginModalContext);

	useEffect(() => {
		if (heartLoading) {
			document.body.classList.add("wait");
		} else {
			document.body.classList.remove("wait");
		}
	}, [heartLoading]);

	function handleBookmarkHeartClick(artId) {
		setupAuthInterceptor(setLoginVisible);
		setHeartLoading(true);
		const heartIcon = document.getElementById("heart-" + artId);
		const currentClassName = heartIcon.className.baseVal;
		if (currentClassName.includes("text-secondary")) {
			AuthAxiosRequest.post(`user/bookmarks/`, { artId: artId })
				.then((response) => {
					if (response.status === 200) {
						notification.success({
							message: "Successfully bookmarked",
						});
					}
					heartIcon.setAttribute("class", "flex-end");
					setHeartLoading(false);
				})
				.catch((error) => {
					notification.error({
						message: "Could not bookmark this item",
					});
					setHeartLoading(false);
				});
		} else {
			AuthAxiosRequest.delete(`user/bookmarks/delete/${artId}/`)
				.then((response) => {
					if (response.status === 204) {
						notification.success({
							message: "Removed from bookmarks",
						});
					}
					heartIcon.setAttribute("class", "flex-end text-secondary");
					setHeartLoading(false);
				})
				.catch((error) => {
					notification.error({
						message: "Could not be removed from bookmarks",
					});
					setHeartLoading(false);
				});
		}
	}

	return (
		<div className="dashboard-auction-card px-2">
			<Row
				className="my-2 border border-secondary p-3 dashboard-auction-card-row"
				role="button"
			>
				<Col md="4">
					<Image
						className="dashboard-auction-card-image shadow-lg"
						src={artData.image}
						style={{ height: "250px", width: "100%", objectFit: "cover" }}
						width="100%"
					></Image>
				</Col>
				<Col md="8">
					<Row className="dashboard-auction-card-body py-4">
						<Col md="9">
							<Link
								to={"/art/" + artData.name + "/"}
								style={{ textDecoration: "none", color: "black" }}
							>
								<h3>{artData.name}</h3>
							</Link>
							<div style={{ display: "flex", flexDirection: "row" }}>
								<div className="center-div">
									{artData.live ? (
										<TbLivePhoto
											style={{
												fontSize: "1rem",
												color: "red",
											}}
										/>
									) : new Date(artData.auction_start_datetime) > new Date() ? (
										<AiOutlineFieldTime
											style={{
												color: "red",
												fontSize: "1.2rem",
											}}
										/>
									) : null}
								</div>
								<p className="m-0 text-danger">
									{artData.live ? (
										"Live"
									) : new Date(artData.auction_start_datetime) > new Date() &&
									  artData.auction_start_datetime ? (
										<CountDown
											deadline={artData.auction_start_datetime}
											style={{
												fontSize: "1rem",
												color: "red",
												margin: "0",
											}}
										/>
									) : artData.bought_at_one_price ||
									  artData.total_bids_amount >= artData.reserve_price ? (
										<p
											className="px-1"
											style={{
												color: "white",
												fontSize: "0.7rem",
												backgroundColor: "#58C84D",
											}}
										>
											{" "}
											Sold
										</p>
									) : (
										""
									)}
								</p>
							</div>
							{!artData.bought_at_one_price &&
								!(artData.total_bids_amount >= artData.reserve_price) && (
									<div className="row-div">
										<p className="pre-text" style={{ fontWeight: "600" }}>
											Total Bid:{" "}
										</p>{" "}
										<span> $</span>
										{Number(artData.total_bids_amount).toLocaleString() || 0}
										<br />
									</div>
								)}
							{artData.bought_at_one_price ||
							artData.total_bids_amount >= artData.reserve_price ? (
								""
							) : (
								<span
									className="bg-danger px-1"
									style={{ color: "white", fontSize: "0.7rem" }}
								>
									Reserve not met
								</span>
							)}
							<div className="left-div">
								<p className="pre-text">
									<span style={{ fontWeight: "600" }}>Lot: </span>
									{artData && artData.lot}
								</p>
							</div>
							{artData && artData.artist_name && (
								<div className="left-div">
									<p className="artist_name">
										<span style={{ fontWeight: "600" }}>Artist: </span>
										{artData && artData.artist_name}
									</p>
								</div>
							)}
							<div className="row-div">
								<p className="bold-p pre-text" style={{ fontWeight: "600" }}>
									Number of owners:{" "}
								</p>
								<p>{artData.number_of_biders || 0}</p>
							</div>
							<div className="row-div">
								<p className="bold-p pre-text" style={{ fontWeight: "600" }}>
									Description:{" "}
								</p>
								<p>
									{artData.head.length > 50
										? artData.head.substring(0, 47) + "..."
										: artData.head}
								</p>
							</div>
						</Col>
						<Col md="3" className="d-flex justify-content-end">
							<div className="row-div">
								<div className="row-div">
									{artData.bidAmount ? (
										<>
											<p className="pre-text" style={{ fontWeight: "600" }}>
												Your Bid:{" "}
											</p>
											<span> $</span>
											{Number(artData.bidAmount).toLocaleString() || 0}
										</>
									) : (
										<IoMdHeart
											id={"heart-" + artData.id}
											style={{
												color: "red",
												fontSize: "1.7rem",
												textAlign: "right",
												cursor: heartLoading && "wait",
											}}
											className="flex-end"
											onClick={(e) =>
												!heartLoading && handleBookmarkHeartClick(artData.id)
											}
										/>
									)}
									<br />
								</div>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
}
