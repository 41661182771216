import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Select, notification } from "antd";
import { Spin } from "antd";
import { Container } from "react-bootstrap";
import countries from "../../../../data/countries.json";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { AuthContext } from "../../../../Contexts/AuthContext";
import {
	AuthAxiosRequest,
	setupAuthInterceptor,
} from "../../../../Services/Utils/AuthAxiosRequest";
import { LoginModalContext } from "../../../../Contexts/LoginModalContext";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const UserSettings = ({ data }) => {
	const [form] = Form.useForm();
	const [formData, setFormData] = useState({
		name: null,
		email: null,
		address: null,
		phoneNumber: null,
	});
	const [isChangingPassword, setIsChangingPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const [pageLoaded, setPageLoaded] = useState(false);
	const { setLoginVisible } = useContext(LoginModalContext);
	let { setIsAuthenticated } = useContext(AuthContext);
	const navigate = useNavigate();

	useEffect(() => {
		if (!pageLoaded) {
			document.body.classList.add("wait");
		} else {
			document.body.classList.remove("wait");
		}
	}, [pageLoaded]);

	useEffect(() => {
		setupAuthInterceptor(setLoginVisible);
		AuthAxiosRequest.get(`user/details/`)
			.then((response) => {
				setFormData({
					name: response.data.name || null,
					email: response.data.email || null,
					address: response.data.address || null,
					country: response.data.country || null,
					phoneNumber: response.data.phone_number || null,
				});
				form.setFieldsValue({
					name: response.data.name || null,
					email: response.data.email || null,
					address: response.data.address || null,
					phoneNumber: response.data.phone_number || null,
					country: response.data.country || null,
				});
				setPageLoaded(true);
			})
			.catch(() => setPageLoaded(true));
	}, []);

	const handleFormSubmit = (values) => {
		setLoading(true);
		if (isChangingPassword) {
			handlePasswordChange();
			return;
		}
		console.log(values);
		// Submit the form data to the backend using axios
		AuthAxiosRequest.put("user/update/", values)
			.then((response) => {
				if (response.status === 200) {
					// Display a success notification to the user

					// If user changed email, log him out
					if (!response.data.email_confirmed) {
						navigate("/");
						setIsAuthenticated(false);
						setLoginVisible(true);
						notification.success({
							message: "User data updated successfully!",
							description:
								"Please check your email inbox (or spam), and confirm you new email address",
							duration: 0,
							placement: "top",
						});
					}
					// Update the local state with the new form data
					notification.success({
						message: "User data updated successfully!",
						placement: "top",
					});
					setFormData(values);
					form.setFieldsValue(values);
					setLoading(false);
				}
			})
			.catch((error) => {
				if (
					error.response &&
					error.response.data &&
					error.response.data.message
				)
					notification.error({
						message: error.response.data.message,
						placement: "top",
					});
				else
					notification.error({
						message: error.message,
					});
				setLoading(false);
			});
	};

	const handlePasswordChange = () => {
		// Validate the fields
		form
			.validateFields([
				"currentPassword",
				"newPassword",
				"confirmPassword",
				"email",
			])
			.then((values) => {
				if (!values.currentPassword) {
					notification.error({
						message: "Please enter your current password.",
						placement: "top",
					});
					return;
				}

				// Submit the password change request to the backend using axios
				AuthAxiosRequest.put("user/update/", {
					currentPassword: values.currentPassword,
					newPassword: values.newPassword,
					email: values.email,
				})
					.then((response) => {
						if (response.status === 200) {
							// Display a success notification to the user
							notification.success({
								message: "Password changed successfully!",
								placement: "top",
							});

							// Clear the password fields
							form.setFieldsValue({
								currentPassword: "",
								newPassword: "",
								confirmPassword: "",
							});
							setIsChangingPassword(false);
							setLoading(false);
						}
					})
					.catch((error) => {
						notification.error({
							message: "Error",
							description:
								"Could not change password, please check your current password or try again later",
							placement: "top",
						});
						setLoading(false);
					});
			})
			.catch((error) => {
				notification.error({
					message: "Error",
					description: "Error validating your input fields",
					placement: "top",
				});
				setLoading(false);
			});
	};

	const handleFormReset = () => {
		// Reset the form to its initial values
		setIsChangingPassword(false);
		form.resetFields();
	};

	const handlePasswordFieldsChange = () => {
		setIsChangingPassword(true);
	};

	return !pageLoaded ? (
		<div style={{ height: "100vh", width: "100vw" }} className="center-div">
			<Spin spinning={!pageLoaded}></Spin>
		</div>
	) : (
		<Container>
			<div className="my-5">
				<h3 style={{ textAlign: "center" }} className="my-5">
					USER PROFILE
				</h3>
				<Form
					form={form}
					onFinish={handleFormSubmit}
					onReset={handleFormReset}
					initialValues={formData}
					labelCol={{ span: 4 }}
					wrapperCol={{ span: 16 }}
				>
					<Form.Item
						label="Email"
						name="email"
						rules={[
							{ required: true, message: "Please enter your email address." },
						]}
						defaultValue={formData.email}
					>
						<Input />
					</Form.Item>
					<Form.Item label="Name" name="name">
						<Input />
					</Form.Item>
					<Form.Item label="Address" name="address">
						<Input />
					</Form.Item>
					<Form.Item label="Country" name="country">
						<Select
							showSearch
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							{countries.map((country) => (
								<Option key={country.name} value={country.name}>
									{country.name}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item label="Phone Number" name="phoneNumber">
						<PhoneInput defaultCountry="US" international />
					</Form.Item>
					{isChangingPassword && (
						<>
							<Form.Item label="Current Password" name="currentPassword">
								<Input.Password />
							</Form.Item>
							<Form.Item
								label="New Password"
								name="newPassword"
								rules={[
									{
										required: true,
										message: "Please enter your new password.",
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (value && value.length >= 8) {
												return Promise.resolve();
											}
											return Promise.reject(
												new Error(
													"Password must be at least 8 characters long."
												)
											);
										},
									}),
								]}
							>
								<Input.Password onChange={handlePasswordFieldsChange} />
							</Form.Item>
							<Form.Item
								label="Confirm Password"
								name="confirmPassword"
								dependencies={["newPassword"]}
								rules={[
									{
										required: true,
										message: "Please confirm your new password.",
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue("newPassword") === value) {
												return Promise.resolve();
											}
											return Promise.reject(
												new Error(
													"The confirmed password does not match the new password."
												)
											);
										},
									}),
								]}
							>
								<Input.Password />
							</Form.Item>
						</>
					)}
					<Form.Item>
						<Button type="primary" htmlType="submit" loading={loading}>
							{isChangingPassword ? "Save Password" : "Save"}
						</Button>
						<Button
							type="default"
							htmlType="reset"
							style={{ marginLeft: "10px" }}
						>
							Cancel
						</Button>
						{!isChangingPassword && (
							<Button
								type="primary"
								style={{ marginLeft: "10px" }}
								onClick={() => setIsChangingPassword(true)}
							>
								Change Password
							</Button>
						)}
					</Form.Item>
				</Form>
			</div>
		</Container>
	);
};

export default UserSettings;
