import { useEffect, useState, useContext } from "react";
import { Container } from "react-bootstrap";
import DashboardAuctionCard from "../../../../Components/DashboardAuctionCard";
import axios from "axios";
import { Spin } from "antd";
import {
	AuthAxiosRequest,
	setupAuthInterceptor,
} from "../../../../Services/Utils/AuthAxiosRequest";
import { LoginModalContext } from "../../../../Contexts/LoginModalContext";

export default function Bids() {
	const [userBids, setUserBids] = useState(null);
	const { setLoginVisible } = useContext(LoginModalContext);

	useEffect(() => {
		if (!userBids) {
			document.body.classList.add("wait");
		} else {
			document.body.classList.remove("wait");
		}
	}, [userBids]);

	useEffect(() => {
		setupAuthInterceptor(setLoginVisible);
		axios
			.get(process.env.REACT_APP_BACKEND_BASE_URL + "/api/get_all_arts/")
			.then((response) => {
				var data = null;
				if (response.status === 200) {
					data = response.data.filter((obj) => obj.name !== "test");
					AuthAxiosRequest.get("user/bids/").then((response) => {
						if (response.status === 200) {
							const bidsData = response.data.filter((obj) => obj.art !== 5); // 5 is test project
							var tmp = [];

							for (let i = 0; i < bidsData.length; i++) {
								for (let j = 0; j < data.length; j++) {
									if (bidsData[i].art === data[j].id) {
										tmp.push({ ...data[j], bidAmount: bidsData[i].amount });
										break;
									}
								}
							}
							setUserBids(tmp);
						}
					});
				}
			});
	}, []);

	return (
		<div className="user-bids my-5">
			<div className="my-5">
				<h3 style={{ textAlign: "center" }} className="my-5">
					YOUR BIDS
				</h3>
			</div>
			{!userBids ? (
				<div style={{ height: "100vh", width: "100vw" }} className="center-div">
					<Spin spinning={!userBids}></Spin>
				</div>
			) : userBids.length === 0 ? (
				<div className="center-div" style={{ height: "50vh" }}>
					<h5>No bids to show</h5>
				</div>
			) : (
				<Container>
					{userBids.map((artData, id) => {
						return <DashboardAuctionCard artData={artData} />;
					})}
				</Container>
			)}
		</div>
	);
}
