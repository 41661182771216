import "./index.css";
import {
	AuthAxiosRequest,
	setupAuthInterceptor,
} from "../../Services/Utils/AuthAxiosRequest";
import { AuthContext } from "../../Contexts/AuthContext";
import { useEffect, useState, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoMdListBox, IoMdHeart } from "react-icons/io";
import { RiUserSettingsFill } from "react-icons/ri";
import { LoginModalContext } from "../../Contexts/LoginModalContext";

export default function () {
	const [userData, setUserData] = useState({});
	const [progress, setProgress] = useState(0);
	const [pageLoaded, setPageLoaded] = useState(false);
	const { setLoginVisible } = useContext(LoginModalContext);

	const dataKeys = ["address", "phone_number", "name"];
	useEffect(() => {
		setupAuthInterceptor(setLoginVisible);
		AuthAxiosRequest.get(`user/details/`).then((response) => {
			setUserData(response.data);
			setPageLoaded(true);
		});
	}, []);

	useEffect(() => {
		// Calculate progress percentage based on user data
		const dataKeys = ["address", "phone_number", "name", "email"];
		const userDataKeys = Object.keys(userData);
		const filledKeys = userDataKeys.filter(
			(key) =>
				dataKeys.includes(key) && userData[key] !== "" && userData[key] !== null
		);
		console.log(
			filledKeys,
			Math.floor((filledKeys.length / dataKeys.length) * 100)
		);
		const newProgress = Math.floor((filledKeys.length / dataKeys.length) * 100);
		setProgress(newProgress);
	}, [userData]);
	return (
		<div>
			{pageLoaded && progress < 100 && (
				<div className="primary-gradient-background center-div py-1">
					<p style={{ color: "white", textAlign: "center" }}>
						Your profile is {progress}% complete.{"   "}
						<span>
							<Link to="/account/settings">Complete your profile</Link>
						</span>
					</p>
				</div>
			)}
			<div className="account my-5">
				<Container>
					<div className="center-div">
						<h3>Welcome to your account</h3>
					</div>
					<div
						style={{ display: "flex", alignItems: "center", minHeight: "50vh" }}
					>
						<Row className="mx-auto">
							<Col md={4} className="my-2">
								<div className="center-div">
									<Link to="/account/bids" style={{ color: "black" }}>
										<div style={{ maxWidth: "200px", textAlign: "center" }}>
											<IoMdListBox style={{ fontSize: "2rem" }} />
											<p>Track your registered auctions and bids</p>
										</div>
									</Link>
								</div>
							</Col>
							<Col md={4} className="my-2">
								<div className="center-div">
									<Link to="/account/settings" style={{ color: "black" }}>
										<div style={{ maxWidth: "200px", textAlign: "center" }}>
											<RiUserSettingsFill style={{ fontSize: "2rem" }} />
											<p>Edit your account information</p>
										</div>
									</Link>
								</div>
							</Col>
							<Col md={4} className="my-2">
								<div className="center-div">
									<Link to="/account/bookmarks" style={{ color: "black" }}>
										<div style={{ maxWidth: "200px", textAlign: "center" }}>
											<IoMdHeart style={{ fontSize: "2rem" }} />
											<p>View and edit your bookmarks</p>
										</div>
									</Link>
								</div>
							</Col>
						</Row>
					</div>
				</Container>
			</div>
		</div>
	);
}
