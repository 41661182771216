import { Navigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AuthContext } from "../Contexts/AuthContext";
import { LoginModalContext } from "../Contexts/LoginModalContext";
export default function PrivateRoute({ Component }) {
	const { isAuthenticated } = useContext(AuthContext);
	const { loginVisible, setLoginVisible } = useContext(LoginModalContext);

	useEffect(() => {
		if (!isAuthenticated && !loginVisible) {
			setLoginVisible(true);
		}
	}, [isAuthenticated, loginVisible, setLoginVisible]);

	return isAuthenticated ? <Component /> : null;
}
