import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import tick_green from "../../../../Assets/tick_green.png";

function SignUpSuccess() {
	const navigate = useNavigate();

	const handleBack = () => {
		navigate(-2);
	};

	return (
		<div
			style={{
				height: "50vh",
			}}
			className="center-div"
		>
			<div style={{ textAlign: "center" }}>
				<img src={tick_green} alt="Success" style={{ width: "100px" }} />
				<h2>Thanks for signing up!</h2>
				<p>
					To complete sign up, please check your email to confirm your account.
				</p>
				<Button onClick={handleBack}>Back to previous page</Button>
			</div>
		</div>
	);
}

export default SignUpSuccess;
