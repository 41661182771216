import { useState, useContext, useEffect } from "react";
import { Modal, Form, Input, Button } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { notification } from "antd";
import { AuthContext } from "../../../../Contexts/AuthContext";
import PasswordResetModal from "../PasswordReset";
import { LoginModalContext } from "../../../../Contexts/LoginModalContext";
export default function LoginModal() {
	const { setIsAuthenticated } = useContext(AuthContext);
	const { loginVisible, setLoginVisible } = useContext(LoginModalContext);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		console.log(
			"================================================",
			loginVisible
		);
		if (loginVisible) {
			setIsAuthenticated(false);
		}
	}, [loginVisible]);

	const handleCancel = () => {
		setLoginVisible(false);
	};
	const onFinish = async (values) => {
		console.log("Received values of form: ", values);
		setLoading(true);
		await axios
			.post(process.env.REACT_APP_BACKEND_BASE_URL + "/api/token/", values)
			.then((response) => {
				if (response.status === 200) {
					console.log(response);
					localStorage.setItem("access_token", response.data.access);
					localStorage.setItem("refresh_token", response.data.refresh);
					setIsAuthenticated(true);
					setLoginVisible(false);
				}
			})
			.catch((error) => {
				if (
					error.response &&
					error.response.data &&
					error.response.data.message
				) {
					notification.error({
						message: "Login failed",
						description: error.response.data.message,
						placement: "top",
						duration: 0,
					});
				} else {
					notification.error({
						message: "Login failed",
						description: error.message,
						placement: "top",
						duration: 0,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div>
			<Button
				onClick={() => setLoginVisible(true)}
				type="primary"
				id="login-btn"
			>
				Sign In
			</Button>
			<Modal
				title=<p style={{ textAlign: "center" }}>
					Sign in to your account to get recommendations, place bids and
					register for sales
				</p>
				open={loginVisible}
				onCancel={handleCancel}
				footer={[
					<Link to="/signup" className="mx-2">
						<Button key="signup" onClick={handleCancel}>
							Create Account
						</Button>
					</Link>,
					<Button
						key="submit"
						type="primary"
						form="login-form"
						htmlType="submit"
						loading={loading}
					>
						Sign In
					</Button>,
				]}
			>
				<Form name="login-form" onFinish={onFinish}>
					<Form.Item
						name="email"
						rules={[
							{
								type: "email",
								message: "The input is not a valid email address",
							},
							{
								required: true,
								message: "Please input your email",
							},
						]}
					>
						<Input placeholder="Email" />
					</Form.Item>
					<Form.Item
						name="password"
						rules={[
							{
								required: true,
								message: "Please input your password",
							},
						]}
					>
						<Input.Password placeholder="Password" />
					</Form.Item>
				</Form>
				<div>
					<PasswordResetModal />
				</div>
			</Modal>
		</div>
	);
}
