import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { Spin } from "antd";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(true);
	const token = localStorage.getItem("access_token");
	const refreshToken = localStorage.getItem("refresh_token");
	const get_access_token = async (setIsAuthenticated) => {
		try {
			console.log("get_access_token 1");
			const response = await axios.post(
				process.env.REACT_APP_BACKEND_BASE_URL + "/api/token/refresh/",
				{
					refresh: refreshToken,
				}
			);
			console.log("get_access_token 2", response.data.access);
			localStorage.setItem("access_token", response.data.access);
			console.log("Bearer", response.data.access);
			setIsAuthenticated(true);
			setLoading(false);
			console.log("got new access token");
		} catch (error) {
			setIsAuthenticated(false);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (loading) {
			document.body.classList.add("wait");
		} else {
			document.body.classList.remove("wait");
		}
	}, [loading]);

	useEffect(() => {
		if (token) {
			const decodedToken = jwt_decode(token);
			setEmail(decodedToken.user_id);
			const currentTime = Date.now() / 1000;
			if (decodedToken.exp < currentTime + 5) {
				if (refreshToken) {
					try {
						get_access_token(setIsAuthenticated);
					} catch (error) {
						// Refresh token has expired or is invalid, redirect to login page
						console.log("refresh token expired");
						setIsAuthenticated(false);
						setLoading(false);
					}
				} else {
					// No refresh token available, redirect to login page
					console.log("No refresh token available");
					setIsAuthenticated(false);
					setLoading(false);
				}
			} else {
				console.log("00000000 setIsAuthenticated(true)", isAuthenticated);
				setIsAuthenticated(true);
				setLoading(false);
			}
		} else {
			// No access token available, redirect to login page
			console.log("No access token available");
			setIsAuthenticated(false);
			setLoading(false);
		}
	}, []);

	return loading ? (
		<div style={{ height: "100vh", width: "100vw" }} className="center-div">
			<Spin spinning={loading}></Spin>
		</div>
	) : (
		<AuthContext.Provider
			value={{ isAuthenticated, setIsAuthenticated, loading, email }}
		>
			{children}
		</AuthContext.Provider>
	);
};
