import { useEffect, useState, useContext } from "react";
import { Container } from "react-bootstrap";
import DashboardAuctionCard from "../../../../Components/DashboardAuctionCard";
import { Spin } from "antd";
import axios from "axios";
import {
	AuthAxiosRequest,
	setupAuthInterceptor,
} from "../../../../Services/Utils/AuthAxiosRequest";
import { LoginModalContext } from "../../../../Contexts/LoginModalContext";

export default function Bookmarks() {
	const [userBookmarks, setUserBookmarks] = useState(null);
	const { setLoginVisible } = useContext(LoginModalContext);

	useEffect(() => {
		if (!userBookmarks) {
			document.body.classList.add("wait");
		} else {
			document.body.classList.remove("wait");
		}
	}, [userBookmarks]);

	useEffect(() => {
		setupAuthInterceptor(setLoginVisible);
		axios
			.get(process.env.REACT_APP_BACKEND_BASE_URL + "/api/get_all_arts/")
			.then((response) => {
				var data = null;
				if (response.status === 200) {
					data = response.data.filter((obj) => obj.name !== "test");
					AuthAxiosRequest.get("user/bookmarks/").then((response) => {
						if (response.status === 200) {
							const bookmarksData = response.data.filter(
								(obj) => obj.art !== 5
							); // 5 is test project
							console.log("bookmarksData", bookmarksData);
							var tmp = [];
							if (bookmarksData.length > 0) {
								for (let i = 0; i < bookmarksData[0].arts.length; i++) {
									for (let j = 0; j < data.length; j++) {
										if (bookmarksData[0].arts[i] === data[j].id) {
											tmp.push(data[j]);
											break;
										}
									}
								}
							}
							setUserBookmarks(tmp);
						}
					});
				}
			});
	}, []);

	return (
		<div className="user-bookmarks my-5">
			<div className="my-5">
				<h3 style={{ textAlign: "center" }} className="my-5">
					YOUR BOOKMARKS
				</h3>
			</div>
			{!userBookmarks ? (
				<div style={{ height: "100vh", width: "100vw" }} className="center-div">
					<Spin spinning={!userBookmarks}></Spin>
				</div>
			) : userBookmarks.length === 0 ? (
				<div className="center-div" style={{ height: "50vh" }}>
					<h5>No bookmarks to show</h5>
				</div>
			) : (
				<Container>
					{userBookmarks.map((artData, id) => {
						return <DashboardAuctionCard artData={artData} />;
					})}
				</Container>
			)}
		</div>
	);
}
